import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from "../../environments/environment";
import { LocalstorageService } from './localstorage.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    constructor(private http: HttpClient, private _ls:LocalstorageService) {
    }

    public setUserValue(user: any) {
        this._ls.setEmail(user.email);
        this._ls.setName(user.email);
        this._ls.setRole(user.roles);
        this._ls.setCreatedOn(user.createdOn);
    }

    login(email: string, username: string, password: string) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: headers };
        let body = {
            "email":  email,
            "username": username,
            "password":  password
        };

        return this.http.post<any>(`${environment.server}auth/login`, body, options)
        .pipe(map(user => {
            console.log(user);
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this._ls.setToken(user.token);
            }

            return user;
        }));
    }

    logout() {
        // remove user from local storage to log user out
        this._ls.removeToken();
        this._ls.removeAccountInfo();
    }

    isAuthenticated() {
        // get the auth token from localStorage
        let token = this._ls.getToken();

        // check if token is set, then...
        if (token) {
            return true;
        }

        return false;
    }
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  constructor() {

  }

  getToken()  {
    return localStorage.getItem("token");
  }

  setToken(token)  {
    return localStorage.setItem("token", token);
  }

  getConekaCustomerId()  {
    return localStorage.getItem("conekta");
  }

  setConekaCustomerId(cid) {
    return localStorage.setItem("conekta", cid);
  }

  getEmail()  {
    return localStorage.getItem("email");
  }

  setEmail(email)  {
    return localStorage.setItem("email", email);
  }

  getName()  {
    return localStorage.getItem("name");
  }

  setName(name)  {
    return localStorage.setItem("name", name);
  }

  getRole()  {
    return localStorage.getItem("role");
  }

  setRole(role)  {
    return localStorage.setItem("role", role);
  }

  getSubscription()  {
    return JSON.parse(localStorage.getItem("subscription"));
  }

  setSubscription(subscription)  {
    return localStorage.setItem("subscription", JSON.stringify(subscription));
  }

  getCreatedOn()  {
    return localStorage.getItem("createdOn");
  }

  setCreatedOn(createdOn)  {
    return localStorage.setItem("createdOn", createdOn);
  }

  removeToken() {
    localStorage.removeItem("token");
  }

  removeAccountInfo() {
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    localStorage.removeItem("createdOn");
  }
}
